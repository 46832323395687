<template>
  <profile />
</template>

<script>
import Profile from '@/components/Profile'

export default {
  components: {
    Profile,
  },
}
</script>
