<template>
  <div class="wrapper-account">
    <a-modal v-model="deleteModal" class="modal-delete" :footer="null" width="300px">
      <div class="content flex-column d-flex align-items-center">
        <div class="align-self-center">
          <TrashIcon :width="'80'" :height="'80'" />
        </div>

        <div class="mt-2" style="font-family: Open Sans;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: #000000;
          ">
          Apakah anda yakin ingin menghapus alamat?
        </div>

        <div class="mt-3 w-100 d-flex flex-column justify-content-center align-items-center" style="gap: 10px 0px">
          <div class="mx-2 w-100 d-flex align-items-center justify-content-center" style="
              height: 33px;
              left: 119px;
              top: 159px;
              background: #FFFFFF;
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 5px;
              cursor: pointer;
            " @click.prevent="() => onDeleteShippingAddress()">
            <span style="color: #000000" v-if="!loading">
              Hapus
            </span>
            <span style="color: #000000" v-if="loading"> <a-icon type="loading" /> </span>
          </div>
          <div class="mx-2 w-100 d-flex align-items-center justify-content-center" style="
              height: 33px;
              left: 216px;
              top: 159px;
              background: #000000;
              border-radius: 5px;
              cursor: pointer;
            " @click.prevent="() => deleteModal = false">
            <span style="color: #FFFFFF">
              Batalkan
            </span>
          </div>
        </div>
      </div>
    </a-modal>

    <div class="container-breadcrumb">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <a-icon type="home" />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span v-on:click="changeMobileContent('')">Akun</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="mobileContent !== ''">
          <span v-html="mobileContent" />
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="d-flex justify-content-start align-items-center mb-3 container-title">
      <span class="title">
        <h3 style="margin-block: auto;">{{ $t('account.account') }}</h3>
      </span>
    </div>

    <a-row class="container-mobile-content">
      <a-col v-if="mobileContent === ''" :xs="24" :sm="24" :md="0">
        <div v-if="account.detail_user.user.last_name !== 'Guest'" class="profile-section">
          <div class="profile">
            <div class="profile-desc">
              <h3>
                {{ account.detail_user.user.first_name }}
                {{ account.detail_user.user.last_name }}
              </h3>
            </div>
            <div class="icon-setting" v-on:click="changeMobileContent('Profile')">
              <img src="@/resources/images/settings.svg" alt="icon-setting-account" />
            </div>
          </div>
          <div class="profile-phone">{{ account.detail_user.user.email }} | -</div>
          <div class="profile-point">
            <span>Koin Terkumpul <br />
              <span class="totalCoin">{{ toCurrency(availablePoint) }} Koin</span>
            </span>
            <div v-on:click="handleOpenModalCoin" class="provision">LIHAT KETENTUAN KOIN</div>
          </div>
        </div>
        <div v-else>
          <div class="profile">
            <div class="profile-desc">
              <h5>
                {{ account.detail_user.user.first_name }}
                {{ account.detail_user.user.last_name }}
              </h5>
            </div>
            <div class="container-button-guest">
              <button class="btn-log-in" @click.prevent="$router.push({ path: '/auth/login' })">Masuk</button>
              <button class="btn-sign-in" @click.prevent="$router.push({ path: '/auth/register' })">Daftar</button>
            </div>
          </div>
        </div>

        <div v-if="account.detail_user.user.last_name !== 'Guest'" class="account-menu-list">
          <h3 class="title-list">Pengaturan Akun</h3>
          <ul>
            <li v-on:click="changeMobileContent('Daftar Alamat')">
              <span>Daftar Alamat</span>
              <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
            </li>
            <li v-on:click="changeMobileContent('Ubah Kata Sandi')">
              <span>Ubah Kata Sandi</span>
              <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
            </li>
          </ul>
        </div>

        <div class="account-menu-list">
          <div v-if="accountMenu !== null">
            <h3 class="title-list">{{ accountMenu.title }}</h3>
          </div>
          <ul v-for="(item, index) in accountMenu.items" :key="index">
            <li v-on:click="changeDynamicMobileContent(item.title, item.url)">
              <span v-html="item.title" />
              <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
            </li>
          </ul>
          <ul v-if="!isGuest">
            <li v-on:click="logout">
              <span>Keluar</span>
              <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
            </li>
          </ul>
        </div>
      </a-col>
      <a-col v-else :xs="24" :sm="24" :md="0">
        <div>
          <div v-if="mobileContent === 'Profile'">
            <div class="settings-wrapper">
              <h3>Pengaturan Profil</h3>
              <ul>
                <li v-on:click="handleClickEditButton">
                  <span class="title-profile">Nama Pengguna</span>
                  <div class="value-profile">
                    {{ account.detail_user.user.first_name }}
                    {{ account.detail_user.user.last_name }}
                  </div>
                  <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
                </li>
                <li v-on:click="handleSentOtp('email', 'edit')">
                  <span class="title-profile">Email</span>
                  <div class="value-profile">
                    {{ account.detail_user.user.email }}
                  </div>
                  <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
                </li>
                <li>
                  <span class="title-profile">Nomor Handphone</span>
                  <div class="value-profile">
                    {{ account.detail_user.user.phone ? account.detail_user.user.phone : '-' }}
                  </div>
                  <img src="@/resources/images/chevron-right.svg" alt="chevron-right-icon" />
                </li>
              </ul>
            </div>
          </div>
          <div v-else-if="mobileContent === 'Daftar Alamat'">
            <div class="title-content">Daftar Alamat</div>
            <div>
              <div class="container-tabs">
                <button>Alamat Pengirim</button>
              </div>
              <div class="container-sub-title">
                <div class="sub-title-account">
                  Daftar Alamat
                </div>
                <button @click.prevent="$router.push({ path: '/account/shipping-address/add' })"
                  class="btn-add-address">
                  Tambah Alamat Baru
                </button>
              </div>
              <div v-if="listShippingAddress.length > 0">
                <div v-for="(item, index) in listShippingAddress" :key="index">
                  <div class="container-adress">
                    <div class="container-text-name">
                      <div class="text-name">{{ `${item.first_name} ${item.last_name} ` }}</div>
                      <div class="container-action-button">
                        <a-dropdown :trigger="['click']">
                          <a-icon type="more" />
                          <a-menu slot="overlay">
                            <a-menu-item>
                              <div class="dropdown-address"
                                v-on:click="$router.push({ path: `/account/shipping-address/edit/${item.id}` })">
                                <img src="@/resources/images/edit-mobile.png" />
                                Ubah
                              </div>
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item>
                              <div class="dropdown-address" v-on:click="selectedId = item.id, deleteModal = true">
                                <img src="@/resources/images/trash-mobile.png" />
                                Hapus
                              </div>
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </div>
                    </div>
                    <div class="text-phone">{{ item.phone }}</div>
                    <div class="text-address">{{ `${item.address_line1 ? item.address_line1 + ', ' : ''}${item.district
                    ?
                    item.district + ', ' : ''
                    }${item.province ? item.province + ', ' : ''}${item.country ? item.country
                      : ''
                    } ${item.postal_code ? item.postal_code : ''} ` }}</div>
                  </div>
                  <div v-if="index + 1 !== listShippingAddress.length">
                    <hr class="line-content" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="mobileContent === 'Ubah Kata Sandi'">
            <div class="title-content">Ubah Kata Sandi</div>
            <hr class="line-content">
            <ChangePassword />
          </div>
          <div v-else>
            <div v-html="htmlData" />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row class="container-desktop-content">
      <a-col :xs="24" :sm="24" :md="8">
        <div class="card-account">
          <div v-if="!isGuest" class="section-name">
            <a-row>
              <a-col :span="8">
                <div class="icon-name">
                  {{
                    account.detail_user?.user &&
                      `${account.detail_user.user?.first_name?.charAt(0).toUpperCase()}${account.detail_user.user?.last_name?.charAt(0).toUpperCase()
                      } `
                  }}
                </div>
              </a-col>
              <a-col :span="14">
                <div class="account-name">
                  {{ account.detail_user.user.first_name }}
                  {{ account.detail_user.user.last_name }}
                </div>
                <div v-if="getLoyaltyState?.statusLoyalty?.value">
                  <span class="label-coin">Koin Terkumpul</span>
                  <div>
                    <span class="coin">{{ toCurrency(availablePoint) }} Koin</span>
                    <span v-on:click="handleOpenModalCoin" class="provision">Lihat Ketentuan</span>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-menu style="width: 100%" mode="inline" :open-keys="openKeys" @openChange="onOpenChange">
            <a-sub-menu key="sub1">
              <template #title><span class="menu-title">Pengaturan Akun</span></template>
              <a-menu-item key="Profile" @click="changeContent"><span class="submenu-title">Profile</span></a-menu-item>
              <a-menu-item v-if="!isGuest" key="Daftar Alamat" @click="changeContent"><span class="submenu-title">Daftar
                  Alamat</span></a-menu-item>
              <a-menu-item v-if="account.detail_user.user.last_name !== 'Guest'" key="Ubah Kata Sandi"
                @click="changeContent"><span class="submenu-title">Ubah Kata
                  Sandi</span></a-menu-item>
            </a-sub-menu>
            <a-sub-menu v-if="accountMenu !== null" key="sub2">
              <template #title><span class="menu-title">{{ accountMenu.title }}</span></template>
              <a-menu-item v-for="(item, index) in accountMenu.items" :key="index"
                @click="changeDynamicContent(item.title, item.url)">
                <span v-html="item.title" class="submenu-title" />
              </a-menu-item>
              <a-menu-item key="8" v-if="!isGuest" @click="logout"><span
                  class="submenu-title">Keluar</span></a-menu-item>
            </a-sub-menu>
          </a-menu>
        </div>
      </a-col>
      <a-col :xs="0" :sm="0" :md="16">
        <div class="wrapper-content-account">
          <div v-if="menuContent === 'Profile'">
            <div class="title-content">Pengaturan Profil</div>
            <hr class="line-content">
            <table v-if="account.detail_user.user.last_name !== 'Guest'">
              <tr>
                <td class="title-table width-title">Nama Pengguna</td>
                <td class="title-table">:</td>
                <td class="value-table">
                  {{ account.detail_user.user.first_name }}
                  {{ account.detail_user.user.last_name }}
                </td>
                <td>
                  <button class="btn-edit-profile" @click="handleClickEditButton">
                    <img src="@/resources/images/edit.png" />
                    Ubah
                  </button>
                </td>
              </tr>
              <tr>
                <td class="title-table width-title">Email</td>
                <td class="title-table">:</td>
                <td class="value-table">{{ account.detail_user.user.email }}</td>
                <td v-if="otpVerificationType.includes('email')">
                  <button class="btn-edit-profile" @click="handleSentOtp('email', 'edit')">
                    <img src="@/resources/images/edit.png" />
                    Ubah
                  </button>
                </td>
              </tr>
              <tr>
                <td class="title-table width-title">Nomor Handphone</td>
                <td class="title-table">:</td>
                <td class="value-table">{{ account.detail_user.user.phone ? account.detail_user.user.phone : '-' }}</td>
                <td v-if="otpVerificationType.includes('wa')">
                  <button class="btn-edit-profile" @click="handleSentOtp('phone', 'edit')">
                    <img src="@/resources/images/edit.png" />
                    Ubah
                  </button>
                </td>
              </tr>
            </table>
            <div v-else class="container-button-guest">
              <button class="btn-log-in" @click.prevent="$router.push({ path: '/auth/login' })">Masuk</button>
              <button class="btn-sign-in" @click.prevent="$router.push({ path: '/auth/register' })">Daftar</button>
            </div>
          </div>
          <div v-else-if="menuContent === 'Daftar Alamat'">
            <div class="title-content">Daftar Alamat</div>
            <div class="card-address">
              <button class="btn-change-address-type">
                Alamat Pengiriman
              </button>
            </div>
            <div class="container-sub-title">
              <div class="sub-title-account">
                Daftar Alamat Pengiriman
              </div>
              <button @click.prevent="$router.push({ path: '/account/shipping-address/add' })" class="btn-add-address">
                Tambah Alamat Pengiriman
              </button>
            </div>
            <div v-if="listShippingAddress.length > 0">
              <div v-for="(item, index) in listShippingAddress" :key="index">
                <div class="container-adress">
                  <div class="container-text-name">
                    <div class="text-name">{{ `${item.first_name} ${item.last_name} ` }}</div>
                    <div class="container-action-button">
                      <button class="btn-edit-profile"
                        @click="$router.push({ path: `/account/shipping-address/edit/${item.id}` })">
                        <img src="@/resources/images/edit.png" />
                        Ubah
                      </button>
                      <button class="btn-delete-profile" @click="selectedId = item.id, deleteModal = true">
                        <img src="@/resources/images/trash.png" />
                        Hapus
                      </button>
                    </div>
                  </div>
                  <div class="text-phone">{{ item.phone }}</div>
                  <div class="text-address">{{ `${item.address_line1 ? item.address_line1 + ', ' : ''}${item.district ?
                  item.district + ', ' : ''
                  }${item.province ? item.province + ', ' : ''}${item.country ? item.country
                    : ''
                  } ${item.postal_code ? item.postal_code : ''}` }}</div>
                </div>
                <div v-if="index + 1 !== listShippingAddress.length">
                  <hr class="line-content" />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="menuContent === 'Ubah Kata Sandi'">
            <div class="title-content">Ubah Kata Sandi</div>
            <hr class="line-content">
            <ChangePassword />
          </div>
          <div v-else>
            <div v-html="htmlData" />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-modal v-model="isModalOTP" :title="verifyType === 'phone' ? $t('account.editPhone') : $t('account.editEmail')"
      :footer="null" :centered="true" :closable="true" :maskClosable="false">
      <div :class="$style.modalOtp">
        <h5 :class="$style.titleInfoOtp">
          Masukkan kode verifikasi yang telah dikirim ke Email
        </h5>
        <div class="mt-2">
          <div class="mb-2 mt-5">
            <div class="d-flex justify-content-center">
              <v-otp-input ref="otpInput" :input-classes="$style.otpInput" separator="" :num-inputs="6"
                :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChangeOTP" />
            </div>
          </div>
          <div class="container-time-otp">
            <a-button class="btn-resend-otp" type="link" :disabled="countDown > 0"
              @click.prevent="handleSentOtp(verifyType, typeAction)">
              {{ $t('verify.otpResend') }}
            </a-button>
            <p class="timerOTP">
              {{ time }}
            </p>
          </div>
          <a-button type="primary" size="large" class="text-center btn-verify" @click.prevent="handleVerify"
            :disabled="otp.length < 6">
            <strong>{{ $t('verify.otpButton') }}</strong>
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isModalForm" :title="verifyType === 'phone' ? $t('account.editPhone') : $t('account.editEmail')"
      :footer="null" :centered="true" :closable="true" :maskClosable="false">
      <div :class="$style.modalOtp">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item v-if="verifyType === 'phone'">
            <div class="label-input">{{ $t('authForm.phoneNumber') }}</div>
            <a-input class="input-edit" size="large" type="text" v-decorator="[
              'phone',
              {
                rules: [{
                  required: true,
                  message: $t('authForm.requiredPhoneNumber'),
                  pattern: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, '')
                }],
              },
            ]" :placeholder="$t('authForm.phoneNumber')" />
          </a-form-item>
          <a-form-item v-if="verifyType === 'email'">
            <div class="label-input">{{ $t('authForm.email') }}</div>
            <a-input class="input-edit" size="large" type="email" :placeholder="$t('authForm.placeholderEmail')"
              v-decorator="['email', {
                rules: [{
                  required: true,
                  validator: (role, value, callback) => {
                    const regex = /\S+@\S+\.\S+/
                    if (!regex.test(value)) {
                      callback('Email invalid')
                    } else if (!value) {
                      callback($t('authForm.requiredEmail'))
                    } else {
                      callback()
                    }
                  },
                  message: $t('authForm.requiredEmail'),
                }]
              }]" />
          </a-form-item>
          <a-button type="primary" size="large" htmlType="submit" class="text-center btn-verify">
            <strong>Simpan</strong>
          </a-button></a-form>
      </div>
    </a-modal>

    <a-modal v-model="isModalSecondOTP"
      :title="verifyType === 'phone' ? $t('account.editPhone') : $t('account.editEmail')" :footer="null"
      :centered="true" :closable="true" :maskClosable="false">
      <div :class="$style.modalOtp">
        <h5 :class="$style.titleInfoOtp">
          Masukkan kode verifikasi yang telah dikirim ke Email
        </h5>
        <div class="mt-2">
          <div class="mb-2 mt-5">
            <div class="d-flex justify-content-center">
              <v-otp-input ref="otpInput" :input-classes="$style.otpInput" separator="" :num-inputs="6"
                :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChangeOTP" />
            </div>
          </div>
          <div class="container-time-otp">
            <a-button class="btn-resend-otp" type="link" :disabled="countDown > 0"
              @click.prevent="handleSentOtp(verifyType, typeAction)">
              {{ $t('verify.otpResend') }}
            </a-button>
            <p class="timerOTP">
              {{ time }}
            </p>
          </div>
          <a-button type="primary" size="large" class="text-center btn-verify" @click.prevent="handleSecondVerify"
            :disabled="otp.length < 6">
            <strong>{{ $t('verify.otpButton') }}</strong>
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="isModalCoin" :title="$t('account.coinTerms')" :footer="null" :centered="false" :closable="true"
      :maskClosable="false">
      <div>{{ getLoyaltyState?.statusLoyalty?.name || '-' }}</div>
      <!-- <div v-html="contentCoinTerm" /> -->
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import ChangePassword from '@/components/ChangePassword'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'profile',
  components: { ChangePassword },
  data: function () {
    return {
      form: this.$form.createForm(this),
      countDown: 300,
      loading: false,
      detailProfile: [],
      countryList: [],
      areaList: [],
      areaName: '',
      cityList: [],
      cityName: '',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      oldEmail: '',
      verifyType: 'email',
      typeAction: 'edit',
      usernameChange: '',
      time: '',
      otp: '',
      isModalOTP: false,
      isModalForm: false,
      isModalSecondOTP: false,
      isModalCoin: false,
      userId: '',
      rootSubmenuKeys: ['sub1', 'sub2'],
      openKeys: ['sub1'],
      selectedKeys: [],
      menuContent: 'Profile',
      mobileContent: '',
      listShippingAddress: [],
      selectedId: null,
      deleteModal: false,
      changeValue: '',
      accountMenu: null,
      htmlData: null,
      availablePoint: 0,
      contentCoinTerm: '',
      otpVerificationType: [],
    }
  },
  watch: {
    '$route.query.menu': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === 'about') {
          this.menuContent = '"Tentang Sarung Indonesia"'
          this.mobileContent = '"Tentang Sarung Indonesia"'
        } else if (value === 'privacy-policy') {
          this.menuContent = '"Kebijakan &amp; Privasi"'
          this.mobileContent = '"Kebijakan &amp; Privasi"'
        } else if (value === 'term-condition') {
          this.menuContent = '"Syarat &amp; Ketentuan"'
          this.mobileContent = '"Syarat &amp; Ketentuan"'
        } else if (value === 'customer-service') {
          this.menuContent = '"Kontak Layanan Pelanggan"'
          this.mobileContent = '"Kontak Layanan Pelanggan"'
        }
      },
    },
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          value.map((item) => {
            if (item.meta_name === 'account_block_1') {
              this.accountMenu = JSON.parse(String(item.meta_value))
            }
            if (item.meta_name === 'coin_term') {
              this.contentCoinTerm = item.meta_value
            }
            if (item.meta_name === 'otp') {
              this.otpVerificationType = JSON.parse(String(item.meta_value))
            }
          })
        }
      },
    },
  },
  mounted() {
    this.detailProfile = this.account
    this.fetchDetailUser()
    this.getLoyaltyCoin()
    this.fetchRegionCountry()
    this.fetchShippingAddress({ addr_key: 'all' })
    setTimeout(() => {
      this.fetchAreaList(this.account.detail_user.address.country_id, 'city')
    }, 750)
    setTimeout(() => {
      this.fetchAreaList(this.account.detail_user.address.city_id, 'area')
      this.loading = false
    }, 1500)
  },
  computed: {
    ...mapState(['app']),
    ...mapState(['settings']),
    ...mapState(['data']),
    ...mapState(['user']),
    ...mapGetters('user', ['isGuest']),
    ...mapState(['account'], {
      detail_user: (state) => state.detail_user,
    }),
    user_address() {
      return this.account.detail_user?.user?.address
    },
    isVendorKino() {
      return this.$store.state.app.vendor_name && this.$store.state.app.vendor_name.toLowerCase() === 'kino'
    },
    urlImage() {
      return this.isVendorKino ? 'https://ik.imagekit.io/powerbiz/img/kino/master_copy.png?updatedAt=1640933165040' : this.$store.state.app.logo
    },
    getLoyaltyState() {
      return this.$store.state.loyalty
    },
  },
  methods: {
    getLoyaltyCoin() {
      this.$store
        .dispatch('loyalty/GETLOYALTY', {})
        .then(({ data }) => {
          this.availablePoint = data.available_point
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false
        })
      this.$store
        .dispatch('loyalty/GETLOYALTYACTIVE', {})
        .then(({ data }) => {
        })
        .catch((err) => console.error(err))
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    getHtmlData(url) {
      this.$store.dispatch('meta/GENERATEPAGEABOUT', {
        path: url,
      })
        .then(({ data }) => {
          this.htmlData = data
        })
        .catch(() => {
          this.htmlData = null
        })
    },
    changeContent(e) {
      this.menuContent = e.key
    },
    changeDynamicContent(path, url) {
      const newUrl = url.split('/')[1]
      this.menuContent = path
      this.getHtmlData(newUrl)
    },
    changeMobileContent(e) {
      this.mobileContent = e
    },
    changeDynamicMobileContent(path, url) {
      const newUrl = url.split('/')[1]
      this.mobileContent = path
      this.getHtmlData(newUrl)
    },
    fetchShippingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          this.listShippingAddress = data.filter((obj) => !obj.deleted_at) ?? []
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    handleOpenModalCoin() {
      this.isModalCoin = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleClickEditButton() {
      this.$router.push({ path: '/account/edit-profile' })
    },
    fetchDetailUser() {
      this.$store
        .dispatch('account/GETDETAILUSER', {
          buyer_id: this.$store.state.user.user_data.buyer_id,
        })
        .then((data) => {
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Sorry, error get data user detil',
            placement: 'bottomRight',
            description: err.response?.data?.message || err.message,
          })
        })
    },
    fetchRegionCountry() {
      this.$store
        .dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list
          const getIndonesia = data.list.find(
            (item) => item.label.toLowerCase() === 'indonesia')
          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    fetchAreaList(parentId, param) {
      this.loading = true
      this.$store
        .dispatch('data/GETAREALLIST', { parentId })
        .then((data) => {
          switch (param) {
            case 'city':
              this.cityList = data.list
              break
            case 'area':
              this.areaList = data.list
              break
            default:
              break
          }
        })
        .then(() => {
          if (param === 'city') {
            this.cityName = this.cityList.filter(
              (e) => +e.value === this.account.detail_user.user.address.city_id)[0]
          } else {
            this.areaName = this.areaList.filter(
              (e) => +e.value === this.account.detail_user.user.address.area_id)[0]
          }
        })
    },
    handleSentOtp(username, type) {
      this.verifyType = username
      this.typeAction = type
      this.usernameChange = username
      this.isModalOTP = true

      const payload = {
        verifyType: this.verifyType,
        user_id: this.account.detail_user.user.id,
        type: 'REQUEST_UPDATE_PROFILE',
      }
      this.$store.dispatch('user/SENTOTP', payload)
        .then(() => {
          this.countDown = 300
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleSubmitOTP() {
      this.isModalOTP = false
    },
    handleOnChangeOTP(value) {
      this.otp = value
    },
    handleVerify() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.account.detail_user.user.id,
        otp: this.otp,
      }
      this.$store.dispatch('user/VERIFYOTP', payload)
        .then((response) => {
          const username_data = {
            user_type: this.usernameChange,
            action_type: this.typeAction,
            user_id: this.account.detail_user.user.id,
          }
          Cookies.set('username_edit_data', JSON.stringify(username_data))
          this.isModalForm = true
          this.isModalOTP = false
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1

          let minutes = Math.floor(this.countDown / 60)
          minutes = minutes < 10 ? `0${minutes}` : minutes
          let seconds = this.countDown - minutes * 60
          seconds = seconds < 10 ? `0${seconds}` : seconds
          this.time = `${minutes} : ${seconds}`

          this.countDownTimer()
        }, 1000)
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) return
        const payload = {
          userType: this.user_type,
          userId: this.user_id,
          dataUpdate: this.user_type === 'email' ? values.email : values.phone,
          isVerifier: this.isVerifier,
        }
        this.$store.dispatch('user/UPDATEUSERNAME', payload)
          .then((response) => {
            const payload = {
              verifyType: this.user_type,
              user_id: this.user_id,
              type: 'UPDATE_PROFILE',
            }
            this.$store.dispatch('user/SENTOTP', payload)
              .then(() => {
                this.countDown = 300
                this.isModalSecondOTP = true
                this.isModalForm = false
              })
              .catch(err => {
                this.$notification.error({
                  message: this.$t('authForm.registerFailed'),
                  description: err.response?.data?.message || err.message || 'Internval Server Error',
                })
              })
          })
          .catch(err => {
            this.$notification.error({
              message: this.$t('authForm.registerFailed'),
              description: err.response?.data?.message || err.message || 'Internval Server Error',
            })
          })
      })
    },
    handleSecondVerify() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.account.detail_user.user.id,
        otp: this.otp,
      }
      this.$store.dispatch('user/VERIFYOTP', payload)
        .then((response) => {
          Cookies.remove('username_edit_data')
          this.isModalSecondOTP = false
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    onDeleteShippingAddress() {
      this.$store.dispatch('purchase/DELSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        id: this.selectedId,
      })
        .then(({ data }) => {
          this.selectedId = null
          this.deleteModal = false
          this.fetchShippingAddress({ addr_key: 'all' })
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    logout() {
      this.$store.dispatch('user/LOGOUT').then(() => {
        if (this.$store.state.app.store_url) {
          window.href = this.$store.state.app.store_url
        } else {
          this.$router.push('/')
        }
        this.$emit('handleDrawer', false)
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  created() {
    this.countDownTimer()
    this.fetchRegionCountry()
    this.fetchAreaList()
    this.$store.dispatch('account/GETDETAILUSER')
    const data = Cookies.get('username_edit_data') ? JSON.parse(Cookies.get('username_edit_data')) : {}
    this.action_type = data.action_type
    this.user_id = data.user_id
    this.user_type = data.user_type
  },
}
</script>
<style lang="scss" module>
@import "@/components/Profile/style.module.scss";
</style>
<style lang="scss" scoped>
.container-desktop-content {
  @media (max-width: 768px) {
    display: none;
  }
}

.container-mobile-content {
  @media (min-width: 769px) {
    display: none;
  }
}

.container-breadcrumb {
  margin-bottom: 1.5rem;

  @media (min-width: 769px) {
    display: none;
  }
}

.container-title {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.wrapper-account {
  padding: 0 55px;

  @media (max-width:768px) {
    padding: 0;
  }
}

.card-account {
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #F3F3F3;
  border-radius: 6px;
}

.section-name {
  padding: 24px
}

.icon-name {
  width: 86px;
  height: 86px;
  border-radius: 43px;
  background-color: #EC1C231A;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #AD2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 74px;
}

.account-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 8px;
}

.label-coin {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--biz-tertiary-100);
}

.coin {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--biz-tertiary-100);
}

.provision {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: var(--biz-accent-2);
  margin-left: 8px;
  cursor: pointer;
}

.menu-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.submenu-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: #FBFBFB !important;

  &:after {
    opacity: 0 !important;
  }
}

.wrapper-content-account {
  padding-left: 24px;
}

.title-content {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 24px;
}

.line-content {
  border: 1px solid #F3F3F3;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding-bottom: 32px;
}

.title-table {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #B6B6B6;
}

.width-title {
  width: 150px;
}

.value-table {
  width: 250px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.btn-edit-profile {
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #43A6E4;

  img {
    margin-right: 4px;
    width: 18px;
    height: 18px;
  }
}

.btn-delete-profile {
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF685E;
  margin-left: 20px;

  img {
    margin-right: 4px;
    width: 18px;
    height: 18px;
  }
}

.card-address {
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  margin-bottom: 32px;
}

.btn-change-address-type {
  padding: 12px;
  background: var(--biz-brand-button);
  border-radius: 8px;
  color: #ffffff;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.container-sub-title {
  display: flex;
  justify-content: space-between;

  @media (max-width:768px) {
    margin-bottom: 24px;
  }
}

.btn-add-address {
  background: #FFFFFF;
  border: 1px solid var(--biz-brand-button);
  border-radius: 6px;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--biz-brand-button);

  @media (max-width: 768px) {
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: var(--biz-accent-2);
    padding: 0;
  }
}

.sub-title-account {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  @media (max-width:768px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}

.container-adress {
  width: 100%;
  padding: 15px;

  .container-text-name {
    display: flex;
    justify-content: space-between;

    .text-name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 13px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .text-phone {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }

  .text-address {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #767676;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.user_detail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }
}

.header {
  position: absolute;
  width: 62px;
  height: 26px;
  left: 457px;
  top: 166px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}

.name {
  /* Subtitle/Medium */

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.namewithaddress {
  /* Subtitle/Medium */

  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: black;
}

.id {
  /* Body/SM/Regular */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.verified {
  color: #015CA1;
  border: 1px solid #015CA1;
  background: #E8F6FE;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
  font-size: 12px;
}

.unverified {
  color: #FF0000;
  border: 1px solid #FF0000;
  background: #FFE0E0;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
  font-size: 12px;
}

.account-section {
  display: flex;
}

.username-section {
  min-width: 350px;
}

.title-modal {
  text-align: center;
  font-size: 20px;
  color: #000;
}

.modal-otp {
  text-align: center;
}

.title-modal-otp {
  color: #000000;
  padding-bottom: 15px;
}

.otp-input {
  width: 57px;
  padding: 0;
  text-align: center;
  margin: 0 3px;
  border: 0;
  border-bottom: 3px solid #D2D2D2;
  font-size: 61px;
  color: #000000;

  &.error {
    border: 1px solid red !important;
  }

  &:focus,
  &.focus {
    border-color: #FF8300;
    outline: none;
    box-shadow: none;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container-time-otp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timerOTP {
  padding-top: 15px;
}

.timer-otp {
  color: #FF8300;
  font-size: 16px;
}

.subtitle-otp {
  color: #000000;
  font-size: 16px;
  margin-top: 8px;
}

.btn-verify {
  background-color: var(--biz-brand-button);
  width: 100%;
  margin: 24px 0;

  &:hover {
    background-color: var(--biz-brand-button);
  }

  &:focus {
    background-color: var(--biz-brand-button);
  }

  &:disabled {
    background-color: var(--biz-primary-20);
    color: #ffffff;
  }
}

.label-input {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #8B8B8B;
  padding-bottom: 8px;
  text-align: left;

  &::after {
    content: '*';
  }
}

.input-edit {
  padding: 9px 16px !important;
  background: #FFFFFF !important;
  border: 1px solid #E3E3E3 !important;
  border-radius: 6px !important;
}

// New style UI
.section-card {
  margin: 26px 70px;
}

.btn-edit {
  width: 110px;
  background: #E8F6FE;
  border: 1px solid #015289;
  color: #015289;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;

  &:hover {
    background: #cfeafa;
    color: #015289;
  }
}

.btn-view {
  width: 110px;
  background: #D7F6DA;
  border: 1px solid #2CBD12;
  color: #2CBD12;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;

  &:hover {
    background: #c3f6c8;
    color: #2CBD12;
  }
}

.btn-add {
  width: 110px;
  background: #015289;
  border: 1px solid #015289;
  color: white;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;

  &:hover {
    background: #1b78b8;
    border: 1px solid #1b78b8;
    color: white;
  }
}

.verifier {
  color: #F5831F;
  border: 1px solid #F5831F;
  background: #FFF2E6;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
}

.profile-section {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border-bottom: 1px solid #F3F3F3;

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .profile-desc {
      display: flex;
      flex-direction: column;

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #171520;
      }
    }
  }

  .profile-phone {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7E7E7E;
  }

  .profile-point {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 9px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--biz-tertiary-100);

      .totalCoin {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.account-menu-list {
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;

  h3.title-list {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  ul {
    width: 100%;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #F3F3F3;
      padding: 16px 0 16px 0;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background: #F3F3F3
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }
    }
  }
}

.settings-wrapper {
  padding: 0;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  ul {
    width: 100%;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    li {
      align-items: center;
      border-bottom: 2px solid #F3F3F3;
      padding: 16px 0 16px 0;
      cursor: pointer;

      &:hover {
        background: #F3F3F3;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      .title-profile {
        position: relative;
        left: 0px;
        color: #B6B6B6;
      }

      .value-profile {
        margin-top: -20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        left: 50%;
        text-align: left;
        color: #000000;
        white-space: nowrap;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        position: absolute;
        right: 10px;
      }
    }
  }
}

.container-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--biz-primary-20);
  margin-bottom: 24px;

  button {
    border: none;
    background-color: transparent;
    padding: 8px;
    width: 50%;
    color: var(--biz-accent-3);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 2px solid var(--biz-accent-3);
  }
}

.container-button-guest {
  display: flex;
  justify-content: space-between;

  button {
    width: 45%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .btn-log-in {
    background-color: var(--biz-brand-button);
    color: #ffffff;
    border-color: var(--biz-brand-button);
  }

  .btn-sign-in {
    background-color: #fff;
    color: var(--biz-brand-button);
    border-color: var(--biz-brand-button);
  }
}

.dropdown-address {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--biz-primary-20);

  img {
    width: 15px;
    margin-right: 10px;
  }
}

.title-coin-terms {
  color: var(--biz-brand);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;

  @media (max-width: 576px) {
    font-size: 12px;
  }
}

.table-detail-coin {
  tr {
    td {
      padding-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .rules-coin {
      color: #777777;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      width: 40%;

      @media (max-width: 576px) {
        font-size: 12px;
        width: 60%;
      }
    }

    .total-coin {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
}

.disclaimer-coin {
  color: var(--biz-accent-2);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 576px) {
    font-size: 12px;
  }
}
</style>
