<template>
  <a-form :form="form" @submit.prevent="submit">
    <a-form-item>
      <div class="container-label">
        <label class="label-input">{{ $t('form.current_password') }}</label>
        <div class="text-forgot-password">{{ $t('authForm.forgotPassword') }}</div>
      </div>
      <a-input-password class="input-password" size="large" type="password"
        v-decorator="['password', { rules: rules.password }]" />
    </a-form-item>

    <a-form-item>
      <label class="label-input">{{ $t('form.new_password') }}</label>
      <a-input-password class="input-password" size="large" type="password"
        v-decorator="[
          'new_password',
          {
            rules: [
              {
                required: true,
                min: 8,
                message: this.$t('authForm.minRequiredPassword'),
                pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),
              },
            ]
          }
        ]"
      />
    </a-form-item>

    <a-form-item>
      <label class="label-input">{{ $t('form.confirm_new_password') }}</label>
      <a-input-password class="input-password" size="large" type="password"
        v-decorator="[
          'confirm_password',
          {
            rules: [
              {
                validator: (rule, value, callback) => value && value !== form.getFieldValue('new_password') ? callback($t('form.confirmPasswordNotMatch')) : callback(),
                message: $t('form.confirmPasswordNotMatch'),
              },
            ],
          }
        ]"
      />
    </a-form-item>

    <slot name="footer">
      <div class="container-button text-right">
        <a-button type="primary" :loading="isLoading" html-type="submit" size="large" block class="btn-submit mt-3">
          {{ $t('topBar.change_password') }}
        </a-button>
      </div>
    </slot>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      isLoading: false,
      rules: {
        password: [
          {
            required: true,
            message: this.$t('form.passwordRequired'),
          },
        ],
        new_password: [
          {
            required: true,
            message: this.$t('form.newPasswordRequired'),
          },
          {
            min: 8,
            message: this.$t('form.passwordValidate'),
          },
          {
            validator: (rule, value, callback) => {
              const containsUppercase = /[A-Z]/.test(value)
              const containsLowercase = /[a-z]/.test(value)
              const containsNumber = /[0-9]/.test(value)
              // eslint-disable-next-line standard/no-callback-literal
              value && value.length >= 8 && (containsUppercase === false || containsLowercase === false || containsNumber === false) ? callback('harus huruf kapital') : callback()
            },
            message: this.$t('form.passwordValidate'),
          },
        ],
        confirm_password: [
          {
            required: true,
            message: this.$t('form.confirmPasswordRequired'),
          },
          {
            // eslint-disable-next-line standard/no-callback-literal
            validator: (rule, value, callback) => value && value !== this.form.getFieldValue('new_password') ? callback('Confirm password does not match') : callback(),
            message: this.$t('form.confirmPasswordNotMatch'),
          },
        ],
      },
    }
  },
  methods: {
    submit() {
      this.form.validateFields(async (errors, form) => {
        if (errors) {
          return
        }

        const payload = {
          ...form,
          channel_id: this.$store.state.app.channel_id,
          user_login: this.$store.state.account.detailCustomer.user.user_name,
        }
        try {
          this.isLoading = true
          // eslint-disable-next-line no-unused-vars
          const _response = await this.$store.dispatch('user/RESETPASSWORD', payload)

          this.$notification.success({
            message: this.$t('form.successConfirm'),
            placement: 'bottomRight',
          })
          this.form.resetFields()
          this.isLoading = false
        } catch (err) {
          if (err?.response?.data?.message === 'Current Password Not Match!') {
            this.$notification.error({
              message: this.$t('form.passwordNotMatch'),
            })
          } else {
            this.$notification.error({
              message: err?.response?.data?.message || err.message,
              // placement: 'bottomRight'
            })
          }
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container-label {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .text-forgot-password {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #8B8B8B;
  }
}

.label-input {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #8B8B8B;
  padding-bottom: 8px;

  &::after {
    content: '*';
  }
}

.input-password {
  input {
    padding: 9px 16px !important;
    background: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 6px !important;

  }
}

.container-button {
  width: 100%;
}

.btn-submit {
  position: absolute;
  right: 0;
  background-color: var(--biz-brand-button);
  width: auto;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  &:hover {
    background-color: var(--biz-primary-100);
  }
}
</style>
