var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-form-item',[_c('div',{staticClass:"container-label"},[_c('label',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('form.current_password')))]),_c('div',{staticClass:"text-forgot-password"},[_vm._v(_vm._s(_vm.$t('authForm.forgotPassword')))])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: _vm.rules.password }]),expression:"['password', { rules: rules.password }]"}],staticClass:"input-password",attrs:{"size":"large","type":"password"}})],1),_c('a-form-item',[_c('label',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('form.new_password')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'new_password',
        {
          rules: [
            {
              required: true,
              min: 8,
              message: this.$t('authForm.minRequiredPassword'),
              pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),
            },
          ]
        }
      ]),expression:"[\n        'new_password',\n        {\n          rules: [\n            {\n              required: true,\n              min: 8,\n              message: this.$t('authForm.minRequiredPassword'),\n              pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@*()?<>~`,./|{}#$%^&+_=-]).{8,}$/g, ''),\n            },\n          ]\n        }\n      ]"}],staticClass:"input-password",attrs:{"size":"large","type":"password"}})],1),_c('a-form-item',[_c('label',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('form.confirm_new_password')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'confirm_password',
        {
          rules: [
            {
              validator: (rule, value, callback) => value && value !== _vm.form.getFieldValue('new_password') ? callback(_vm.$t('form.confirmPasswordNotMatch')) : callback(),
              message: _vm.$t('form.confirmPasswordNotMatch'),
            },
          ],
        }
      ]),expression:"[\n        'confirm_password',\n        {\n          rules: [\n            {\n              validator: (rule, value, callback) => value && value !== form.getFieldValue('new_password') ? callback($t('form.confirmPasswordNotMatch')) : callback(),\n              message: $t('form.confirmPasswordNotMatch'),\n            },\n          ],\n        }\n      ]"}],staticClass:"input-password",attrs:{"size":"large","type":"password"}})],1),_vm._t("footer",function(){return [_c('div',{staticClass:"container-button text-right"},[_c('a-button',{staticClass:"btn-submit mt-3",attrs:{"type":"primary","loading":_vm.isLoading,"html-type":"submit","size":"large","block":""}},[_vm._v(" "+_vm._s(_vm.$t('topBar.change_password'))+" ")])],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }